import { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Login from "./components/Login"
import Layout from "./Layout"
import api from "./api"

function App() {

  const [signIn, setSignIn] = useState(false);
  const [auth, setAuth] = useState(true);
  const [message, setMessage] = useState("")

  const theme = createTheme({ palette: { mode: 'dark' } });

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      api.get("/me")
        .then(res => {
          if (res.status === 200) setSignIn(true)
          setAuth(false)
        })
        .catch(err => {
          console.log("Server error")
          setAuth(false)
        })
    }
    else setAuth(false)
  }, [])

  function handleSignIn(username, password) {
    api.post("/login", { username, password })
      .then(res => {
        api.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
        localStorage.setItem('accessToken', res.data.accessToken)
        setSignIn(true)
      })
      .catch(err => {
        console.log(err)
        setMessage("Indentifiant et/ou mot de passe invalide")
      })
  }
  if (auth) return (
    <div style={{ textAlign: 'center', margin: 10 }}>
      Authentification en cours...
    </div>
  )

  return (
    <ThemeProvider theme={theme}>
      {signIn
      ? <Layout api={api} />
      : <Login api={api} onSignIn={handleSignIn} message={message} />
      }
    </ThemeProvider>
  )
}

export default App;
