import { useState, useEffect, useRef } from "react";
import { Box, Paper, Fab, Alert, LinearProgress, Chip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'

import api from "../api"
import Rechercher from "./Rechercher";
import Peinture from './Peinture';
import PeintureEdit from "./PeintureEdit";
import PeintureOptions from "./PeintureOptions";
import Confirm from "./Confirm";

import { marques } from "../config"

const optionsVierge = {
  marque: "*",
  famille: "*",
  base: "*",
  stock: -1,
  tri: "nm",
}

export default function Peintures(props) {

  let listeRef = useRef()

  const [options, setOptions] = useState({ ...optionsVierge })
  const [itemId, setItemId] = useState(null)

  const [recherche, setRecherche] = useState("")
  const [filtreInactif, setFiltreInactif] = useState(true)

  const [openOptions, setOpenOptions] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [messageDelete, setMessageDelete] = useState("")
  const [confirmDelete, setConfirmDelete] = useState(false)

  // const [error, setError] = useState(null)
  // const [isLoaded, setIsLoaded] = useState(false)

  const [total, setTotal] = useState(0)
  // const [liste, setListe] = useState([])
  // const [familles, setFamilles] = useState([])
  // const [couleurs, setCouleurs] = useState([])

  // useEffect(() => {
  //   api.get("/peintures")
  //     .then(response => {
  //       if (response.data.peintures) {
  //         let familles = {}, couleurs = {}
  //         response.data.peintures.forEach(x => {
  //           familles[x.fa] = true
  //           couleurs[x.c] = true
  //         })
  //         setListe(response.data.peintures)
  //         setFamilles(Object.keys(familles).sort())
  //         setCouleurs(Object.keys(couleurs).sort())
  //       }
  //       if (response.data.err) {
  //         console.log(response.data.err)
  //         setError("Pas de peinture !")
  //       }
  //       setIsLoaded(true)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       setError("Problème coté serveur")
  //       setIsLoaded(true)
  //     })
  // }, [])

  useEffect(() => {
    if (listeRef && listeRef.current)
      setTotal(listeRef.current.childNodes.length)
  })

  // if (!isLoaded) return (
  //   <Box sx={{ width: '100%' }}>
  //     <LinearProgress sx={{ margin: 3 }} />
  //   </Box>
  // )
  // if (error) return <Alert severity="error">{error}</Alert>

  //================================================================================
  function handleOpenOptions(clear) {
    if (clear) {
      setOptions({ ...optionsVierge })
      setFiltreInactif(true)
    }
    else setOpenOptions(true)
  }
  function handleCloseOptions(opts) {
    setOpenOptions(false)
    if (opts) {
      setOptions(opts)
      setFiltreInactif(opts.marque === "*" && opts.famille === "*" && opts.base === "*" && opts.stock === -1)
    }
  }
  //================================================================================
  function handleOpenEdit(_id) {
    setItemId(_id)
    setOpenEdit(true)
  }
  function handleOpenNew() {
    setItemId(null)
    setOpenEdit(true)
  }
  function handleCloseEdit(result) {
    setOpenEdit(false)
    if (result === false) return // Annuler
    if (result === true) { // Supprimer
      const item = props.liste.find(x => x._id === itemId)
      if (item) {
        setMessageDelete(`Supprimer ${item.nm} (${marques[item.m]}) ?`)
        setConfirmDelete(true)
      }
    }
    else props.onEdit(result)
  }
  function handleDelete(ok) {
    setConfirmDelete(false)
    if (ok) props.onDelete(itemId)
  }
  //================================================================================
  let re = new RegExp(recherche, "i")
  return (
    <div>

      {/* Liste */}

      <ul style={{ margin: 0, padding: 0, boxSizing: "content-box" }} ref={listeRef}>{
        props.liste.filter(x => {
          if (options.marque !== "*" && x.marque !== options.marque) return false
          if (options.famille !== "*" && x.famille !== options.famille) return false
          if (options.base !== "*" && x.base !== options.base) return false
          if (options.stock !== -1 && x.stock !== options.stock) return false
          return re.test(x.nom) || re.test(x.traduction)
        })
          .sort((a, b) => a[options.tri] > b[options.tri] ? 1 : (a[options.tri] < b[options.tri] ? -1 : 0))
          .map(x => <Peinture key={x._id} {...x} onEdit={handleOpenEdit} />)
      }</ul>

      {/* Spacer */}

      <Box sx={{ height: 200 }} />

      {/* Barre d'outils */}

      <Paper sx={{ position: 'fixed', bottom: 56, left: 0, right: 0, padding: 1, display: "flex" }} elevation={0}>
        <Rechercher onRecherche={recherche => setRecherche(recherche)}></Rechercher>
      </Paper>

      {/* Infos et ajout */}

      <Box sx={{ position: "fixed", bottom: 130, right: 8, display: "flex", alignItems: "flex-end" }}>
        <Chip color={filtreInactif ? "success" : "warning"}
          label={props.liste.length === total ? `Total : ${total}` : `Filtre : ${total} / ${props.liste.length}`}
          onClick={() => handleOpenOptions(false)}
          onDelete={filtreInactif ? null : () => handleOpenOptions(true)}
        />
        &nbsp;&nbsp;
        <Fab color="secondary" onClick={handleOpenNew}><AddIcon /></Fab>
      </Box>

      {/* Dialogues */}

      <PeintureOptions open={openOptions}
        options={options} familles={props.familles} bases={props.bases}
        onClose={handleCloseOptions}
      />
      <PeintureEdit open={openEdit}
        peinture={props.liste.find(x => x._id === itemId)}
        onClose={handleCloseEdit}
      />
      <Confirm open={confirmDelete} onClose={handleDelete}>{messageDelete}</Confirm>
    </div>
  )
}