import { useRef, useState } from 'react'
import { Stack, TextField, Paper, Button } from "@mui/material"

export default function Login({ onSignIn, message }) {

    const usernameRef = useRef()
    const passwordRef = useRef()

    // if (!accessToken) {
    //     const token = localStorage.getItem('accessToken');
    //     if (token) {
    //         api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    //         api.get("/me")
    //             .then(res => {
    //                 setAccessToken(token)
    //             })
    //             .catch(err => {
    //                 console.log("invalid token")
    //             })
    //     }
    // }

    const handleSubmit = e => {
        e.preventDefault()
        onSignIn(usernameRef.current.value, passwordRef.current.value)
    }

    return (
        <Paper elevation={6} sx={{ maxWidth: 250, padding: 3, margin: "200px auto" }}>
            <form onSubmit={handleSubmit} >
                <Stack spacing={1}>
                    <TextField fullWidth variant="standard" inputRef={usernameRef} label="Identifiant" type="text" />
                    <TextField fullWidth variant="standard" inputRef={passwordRef} label="Mot de passe" type="password" />
                    <Button fullWidth type="submit">Connexion</Button>
                    <p>{message}</p>
                </Stack>
            </form>
        </Paper>
    )
};
