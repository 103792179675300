import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Peintures from './components/Peintures';
import Couleurs from './components/Couleurs';
import Projets from './components/Projets';

import { Paper, Box, BottomNavigation, BottomNavigationAction, LinearProgress, Alert } from "@mui/material";

import PaletteIcon from "@mui/icons-material/Palette";
import BrushIcon from "@mui/icons-material/Brush";
import ManIcon from "@mui/icons-material/Man";

function Layout({ api }) {
    const navigate = useNavigate();
    const [url, setUrl] = useState("/");
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

    const [peintures, setPeintures] = useState([])
    const [familles, setFamilles] = useState([])
    const [bases, setBases] = useState([])

    function handleDeletePeinture(itemId) {
        api.delete("/peintures/" + itemId)
            .then(res => {
                if (res.data.doc) {
                    console.log("del", res.data.doc)
                    setPeintures(peintures => peintures.filter(x => x._id !== itemId))
                }
            })
            .catch(err => {
                console.log(err)
                setError("Problème coté serveur")
            })
    }
    function handleEditPeinture(peinture) {

        if (peinture.style.match(/^#[0-9a-zA-Z]+$/)) peinture.rgb = peinture.style
        peinture.famille = peinture.famille.toUpperCase()
        peinture.finition = peinture.finition.toLowerCase()
        peinture.base = peinture.base.toLowerCase()

        if (peinture._id === undefined) { // Ajouter
            api.post("/peintures", peinture)
                .then(res => {
                    if (res.data.doc) {
                        console.log("new", res.data.doc)
                        setPeintures(peintures => peintures.concat(res.data.doc))
                    }
                })
                .catch(err => {
                    console.log(err)
                    setError("Problème coté serveur")
                })
        }
        else { // Editer
            api.put("/peintures", peinture)
                .then(res => {
                    if (res.data.doc.modifiedCount === 1) {
                        setPeintures(peintures => peintures.map(x => x._id === peinture._id ? peinture : x))
                    }
                })
                .catch(err => {
                    console.log(err)
                    setError("Problème coté serveur")
                })
        }
    }

    useEffect(() => {
        api.get("/peintures")
            .then(response => {
                if (response.data.peintures) {
                    let familles = {}, bases = {}
                    response.data.peintures.forEach(x => {
                        familles[x.famille] = true
                        if (x.base !== "") x.base.split(' ').forEach(x => bases[x] = true)
                    })
                    setPeintures(response.data.peintures)
                    setFamilles(Object.keys(familles).sort())
                    setBases(Object.keys(bases).sort())
                }
                if (response.data.err) {
                    console.log(response.data.err)
                    setError("Pas de peinture !")
                }
                setIsLoaded(true)
            })
            .catch(err => {
                console.log(err)
                setError("Problème coté serveur")
                setIsLoaded(true)
            })
    }, [])

    if (!isLoaded) return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress sx={{ margin: 3 }} />
        </Box>
    )
    if (error) return <Alert severity="error">{error}</Alert>

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={
                    <Peintures liste={peintures} familles={familles} bases={bases}
                    onEdit={handleEditPeinture} onDelete={handleDeletePeinture} />
                } />
                <Route path="couleurs" element={<Couleurs />} />
                <Route path="projets" element={<Projets />} />
            </Routes>
            <Paper sx={{ position: 'fixed', paddingBottom: 1, bottom: 0, left: 0, right: 0 }} elevation={0}>
                <BottomNavigation showLabels value={url}
                    onChange={(event, u) => {
                        setUrl(u);
                        navigate(u);
                    }}
                >
                    <BottomNavigationAction label="Peintures" icon={<PaletteIcon />} value="/" />
                    <BottomNavigationAction label="Couleurs" icon={<BrushIcon />} value="/couleurs" />
                    <BottomNavigationAction label="Projets" icon={<ManIcon />} value="/projets" />
                </BottomNavigation>
            </Paper>
        </div>
    );
}

export default Layout;
