import { Component } from "react";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

// import "./Rechercher.css"

export default class Rechercher extends Component {

  constructor(props) {
    super(props)
    this.state = {
      recherche: "",
      timer: 0
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClear = this.handleClear.bind(this)
  }

  handleChange(e) {
    if (this.state.timer) clearTimeout(this.state.timer);
    this.setState({
      recherche: e.target.value,
      timer: setTimeout(() => {
        this.props.onRecherche(this.state.recherche)
      }, 500)
    });
  }
  handleClear(e) {
    this.setState({ recherche: "" }, () => this.props.onRecherche(this.state.recherche))
  }

  render() {
    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="recherche">Rechercher</InputLabel>
        <OutlinedInput
          id="recherche"
          value={this.state.recherche}
          onChange={this.handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Effacer la recherche"
                onClick={this.handleClear}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          }
          label="Rechercher"
        />
      </FormControl>
    )
  }

}