import { useState } from "react";
import { Dialog, DialogContent, DialogActions, Stack, TextField, Button, MenuItem } from "@mui/material";

import { marques, tris, stocks } from "../config";

export default function PeintureOptions(props) {

  const [options, setOptions] = useState(props.options)

  if (!props.open) return null
  
  console.log("state", options)
  console.log("props", props.options)

  function handleChange(e) {
    setOptions({ ...options, [e.target.name]: e.target.value })
  }

  return (
    <Dialog fullWidth open={props.open} onClose={props.onClose}>
      <DialogContent>
        <Stack spacing={1}>
          <TextField fullWidth select id="options-marque" value={options.marque} name="marque" label="Marque" variant="standard" onChange={handleChange} >
            <MenuItem key="*" value="*">Toutes</MenuItem>
            {Object.keys(marques).map(i => {
              return <MenuItem key={i} value={i}>{marques[i]}</MenuItem>
            })}
          </TextField>
          <TextField fullWidth select id="options-famille" value={options.famille} name="famille" label="Famille" variant="standard" onChange={handleChange} >
            <MenuItem key="*" value="*">Toutes</MenuItem>
            {props.familles.map((x, i) => {
              return <MenuItem key={i} value={x}>{x}</MenuItem>
            })}
          </TextField>
          <TextField fullWidth select id="options-base" value={options.base} name="base" label="Couleur de base" variant="standard" onChange={handleChange} >
            <MenuItem key="*" value="*">Toutes</MenuItem>
            {props.bases.map((x, i) => {
              return <MenuItem key={i} value={x}>{x}</MenuItem>
            })}
          </TextField>
          <TextField fullWidth select id="options-stock" value={options.stock} name="stock" label="Stock" variant="standard" onChange={handleChange} >
            <MenuItem key="*" value={-1}>&mdash;</MenuItem>
            {stocks.map((x, i) => {
              return <MenuItem key={i} value={i}>{x}</MenuItem>
            })}
          </TextField>
          <TextField fullWidth select id="options-tri" value={options.tri} name="tri" label="Tri" variant="standard" onChange={handleChange} >
            {Object.keys(tris).map(i => {
              return <MenuItem key={i} value={i}>{tris[i]}</MenuItem>
            })}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.onClose(false)}>Annuler</Button>
        <Button autoFocus variant="contained" color="success" onClick={() => props.onClose(options)}>OK</Button>
      </DialogActions>
    </Dialog>
  )

}