import { Link } from "react-router-dom";

function Couleurs() {
  return (
    <>
      <main>
        <h2>Couleurs</h2>
        <p>You can do this, I believe in you.</p>
      </main>
      <nav>
        <Link to="/">Peintures</Link>
      </nav>
    </>
  );
}

export default Couleurs;