import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogActions, Stack, TextField, Button, MenuItem } from "@mui/material";
import TagsInput from "./TagsInput"

import m from "dayjs"
// import frlocale from "dayjs/locale/fr"

import { marques, stocks } from "../config";

const peintureVierge = {
    marque: "?",
    nom: "",
    traduction: "",
    base: "",
    rgb: "#000000",
    famille: "",
    finition: "",
    style: "",
    lien: "",
    stock: 0,
    date: ""
}

export default function PeintureEdit(props) {

    const [peinture, setPeinture] = useState({ ...peintureVierge })

    useEffect(() => {
        if (props.open) setPeinture(props.peinture
            ? { ...props.peinture, date: m(props.peinture.date).format('YYYY-MM-DD') }
            : { ...peintureVierge, date: m().format('YYYY-MM-DD') })
    }, [props.peinture, props.open])

    function handleChange(e) {
        let name = e.target.name
        let value = e.target.value
        // if (name === "rgb") value = value.split(" ").map(x => parseInt(x))
        setPeinture({ ...peinture, [name]: value })
    }
    function handleSelecetedTags(tags) {
        peinture.base = tags.join(' ')
    }
    return (
        <Dialog fullScreen open={props.open} onClose={props.handleClose}>
            <DialogContent>

                <Stack spacing={1}>
                    <TextField onChange={handleChange} name="marque" value={peinture.marque} variant="standard" fullWidth size="small" label="Marque" select>
                        {Object.keys(marques).map(i => <MenuItem key={i} value={i}>{marques[i]}</MenuItem>)}
                    </TextField>
                    <TextField onChange={handleChange} name="nom" value={peinture.nom} variant="standard" fullWidth size="small" label="Nom" />
                    <TextField onChange={handleChange} name="traduction" value={peinture.traduction} variant="standard" fullWidth size="small" label="Traduction" />
                    {/* <TextField onChange={handleChange} name="base" value={peinture.base} variant="standard" fullWidth size="small" label="Tags" /> */}
                    <TagsInput selectedTags={handleSelecetedTags} name="base" tags={peinture.base ? peinture.base.split(' ') : []}
                        variant="standard" fullWidth label="Mots clés" />
                    <TextField onChange={handleChange} name="rgb" value={peinture.rgb} variant="standard" fullWidth size="small" label="RGB" />
                    <TextField onChange={handleChange} name="famille" value={peinture.famille} variant="standard" fullWidth size="small" label="Famille" />
                    <TextField onChange={handleChange} name="finition" value={peinture.finition} variant="standard" fullWidth size="small" label="Finition" />
                    <TextField onChange={handleChange} name="style" value={peinture.style} variant="standard" fullWidth size="small" label="Style" />
                    <TextField onChange={handleChange} name="lien" value={peinture.lien} variant="standard" fullWidth size="small" label="Lien" />
                    <TextField onChange={handleChange} name="date" value={peinture.date} type="date" variant="standard" fullWidth size="small" label="Date" />
                    <TextField onChange={handleChange} name="stock" value={peinture.stock} variant="standard" fullWidth size="small" label="Stock" select>
                        {Object.keys(stocks).map(i => <MenuItem key={i} value={i}>{stocks[i]}</MenuItem>)}
                    </TextField>
                </Stack>
            </DialogContent>

            <DialogActions sx={{/* justifyContent: "center", */ marginBottom: 3}}>
                {peinture._id && <Button variant="contained" onClick={() => props.onClose(true)} color="warning">Supprimer</Button>}
                <Button autoFocus variant="contained" onClick={() => props.onClose(false)}>Annuler</Button>
                <Button variant="contained" color="success" onClick={() => props.onClose(peinture)}>OK</Button>
            </DialogActions>

        </Dialog>
    )

}