import { Link } from "react-router-dom";

function Projets() {
  return (
    <>
      <main>
        <h2>Projets</h2>
        <p>You can do this, I believe in you.</p>
      </main>
      <nav>
        <Link to="/">Peintures</Link>
      </nav>
    </>
  );
}

export default Projets;