import React, { Component } from "react";
import { IconButton, Box, Chip } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import "./Peinture.css"
import { marques, stock } from "../config";

const couleur = ["info", "secondary", "success", "error"];
//   '-': "block",
//   '0': "shopping_cart",
//   '1': "done",
// }

class Peinture extends Component {

  constructor(props) {
    super(props)
    this.handleEdit = this.handleEdit.bind(this)
  }

  handleEdit() {
    this.props.onEdit(this.props._id)
  }

  render() {
    return (
      <li className="Peinture">

        <button className="Peinture-Couleur" style={{ background: this.props.style || "#000000" }} onClick={this.handleEdit}>{this.props.style ? "" : "?"}</button>

        <div className="Peinture-Noms">
          <strong>{marques[this.props.marque]}</strong>
          <b>{this.props.nom}</b>
          <i>{this.props.traduction}</i>
        </div>

        <Box className="Peinture-Stock">
        <IconButton color={couleur[this.props.stock]}>
          { this.props.stock == 1 && <ShoppingCartIcon /> }
          { this.props.stock == 2 && <DoneIcon /> }
          { this.props.stock == 3 && <BlockIcon /> }
        </IconButton>
        </Box>

        <div className="Peinture-Detail">
          <Chip label={this.props.famille} size="small" />
          <span>{this.props.base}<br/><i>{this.props.finition}</i></span>
        </div>

      </li>
    )
  }

}

export default Peinture