import React from "react"

const marques = {
  '?': "Inconnue",
  'C': "Citadel",
  'AP': "Army Painter",
  'PA': "Prince August",
  'V': "Valejo",
  'D': "Decapod"
};
const stocks = [
  "AUCUN", 
  "ACHAT",
  "STOCK",
  "EPUISE"];
const tris = {
  "nm": "Nom",
  "tr": "Traduction",
  "m": "Marque",
  "c": "Couleur",
  "fa": "Famille",
  "fi": "Finition",
  "n": "Stock",
  "d": "Date"
}
const bases = [
  "argent",
  "blanc",
  "bleu",
  "bronze",
  "brun",
  "chair",
  "cuivre",
  "gris",
  "jaune",
  "laiton",
  "noir",
  "or",
  "orange",
  "os",
  "rose",
  "rouge",
  "turquoise",
  "vert",
  "violet",
]

const devMode = ('_self' in React.createElement('div'))

const apiUrl = devMode
  ? "http://localhost:18001/api/"
  : "/api/"

export {
  marques,
  stocks,
  bases,
  tris,
  apiUrl
}