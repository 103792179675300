import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Confirm(props) {

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>
          {props.title || "Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => props.onClose(null)}>Annuler</Button>
          <Button variant="contained" onClick={() => props.onClose(props.value || true)} autoFocus>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  )

}
